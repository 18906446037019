import React from "react";

function Projects() {
  const projectData = [
    {
      title: "Sky Cast",
      description: "A Responsive Weather report Web App Made using React.js",
      liveDemo: "https://weather-report-app-seven.vercel.app/",
      sourceCode: "https://github.com/Yeabsra-Henok/Sky-Cast.git",
      src: "assets/project-image-4.png",
    },
    {
      title: "World Wise",
      description: "Interactive World Map Travel Booker",
      liveDemo: "https://world-wise-eosin.vercel.app/",
      sourceCode: " https://github.com/Yeabsra-Henok/World-Wise.git",
      src: "assets/project-image-1.png",
    },
    {
      title: "The Atomic Blogger",
      description: "A Responsive Blog Web App built Using React.js",
      liveDemo: "https://the-atomic-blogger-9ufs.vercel.app/",
      sourceCode: "https://github.com/Yeabsra-Henok/The-Atomic-Blogger.git",
      src: "assets/project-image-2.png",
    },
    {
      title: "GymGuard",
      description: "Fitness Tracker App Made Using React.js",
      liveDemo: "https://fitness-tracker-app-henna.vercel.app/",
      sourceCode: "https://github.com/Yeabsra-Henok/Fitness-Tracker-App.git",
      src: "assets/project-image-3.png",
    },
    {
      title: "Pizza Italiano",
      description: "A Pizza Menu Web App For Italain Pizza Restaurant",
      liveDemo: "https://react-pizza-co-alpha.vercel.app/",
      sourceCode: "https://github.com/Yeabsra-Henok/pizza-menu.git",
      src: "assets/project-image-7.png",
    },
    {
      title: "Traevel Item Tracker",
      description: "Travel Item Tracker Made Using React.js",
      liveDemo: "https://travel-item-tracker.vercel.app/",
      sourceCode: "https://github.com/Yeabsra-Henok/Travel-Item-Tracker.git",
      src: "assets/project-image-8.png",
    },
    {
      title: "Eat n' Split",
      description: "A Bill Splitter Web App Made Using React.js",
      LiveDemo: "https://eat-n-split-bill-splitter-web-app.vercel.app/",
      sourceCode:
        "https://github.com/Yeabsra-Henok/Eat-n--Split---Bill-Splitter-Web-App.git",
      src: "assets/project-image-9.png",
    },
    {
      title: "CinemaScore",
      description: "React Web app that lets users to search and rate movies",
      liveDemo: "https://use-popcorn-theta-liart.vercel.app/",
      sourceCode: "https://github.com/Yeabsra-Henok/usePopcorn.git",
      src: "assets/project-image-10.png",
    },
  ];

  return (
    <section id="projects" className="py-16 px-4 sm:px-8 lg:px-32 xl:px-40">
      <h2 className="text-3xl font-bold mb-12 text-center overflow-hidden">
        Projects I've built
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 overflow-hidden">
        {projectData.map((project, index) => (
          <div
            key={index}
            className="bg-gray-800 p-6 rounded-lg transition-transform transform hover:scale-105 overflow-hidden"
          >
            <img
              src={project.src}
              alt={`Screenshot of ${project.title}`}
              className="rounded-lg mb-4"
            />
            <h3 className="text-xl font-bold mb-2">{project.title}</h3>
            <p className="text-sm mb-4">{project.description}</p>
            <div className="flex space-x-4">
              <a
                href={project.liveDemo}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
              >
                Live Demo
              </a>
              <a
                href={project.sourceCode}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center space-x-2"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2C6.48 2 2 6.48 2 12c0 4.418 2.865 8.166 6.839 9.49.5.091.682-.217.682-.482 0-.237-.009-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.154-1.11-1.462-1.11-1.462-.908-.621.069-.608.069-.608 1.004.07 1.531 1.032 1.531 1.032.892 1.529 2.341 1.088 2.911.832.092-.646.35-1.088.637-1.339-2.22-.252-4.555-1.11-4.555-4.944 0-1.092.39-1.984 1.03-2.683-.104-.253-.447-1.273.098-2.652 0 0 .84-.27 2.75 1.026A9.573 9.573 0 0112 6.85c.851.004 1.71.115 2.51.338 1.91-1.296 2.75-1.026 2.75-1.026.545 1.379.202 2.399.1 2.652.64.7 1.03 1.591 1.03 2.683 0 3.842-2.337 4.687-4.565 4.934.36.31.682.921.682 1.856 0 1.34-.012 2.42-.012 2.748 0 .267.18.577.688.48C19.135 20.165 22 16.418 22 12c0-5.52-4.48-10-10-10z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Source Code</span>
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Projects;
