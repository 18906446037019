import React from "react";
import { Link } from "react-scroll";

function Header() {
  return (
    <header className="bg-gray-900 p-4 text-white relative">
      <nav className="flex justify-center space-x-8 mb-8">
        <ul className="flex justify-center space-x-8 mb-8">
          <li>
            <Link
              to="Home"
              smooth={true}
              duration={500}
              className="text-white hover:text-purple-500 cursor-pointer"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="projects"
              smooth={true}
              duration={500}
              className="text-white hover:text-purple-500 cursor-pointer"
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              to="technologies"
              smooth={true}
              duration={500}
              className="text-white hover:text-purple-500 cursor-pointer"
            >
              Technologies
            </Link>
          </li>
          <li>
            <Link
              to="about"
              smooth={true}
              duration={500}
              className="text-white hover:text-purple-500 cursor-pointer"
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="resume"
              smooth={true}
              duration={500}
              className="text-white hover:text-purple-500 cursor-pointer"
            >
              Resume
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              className="text-white hover:text-purple-500 cursor-pointer"
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
      <div className="flex flex-col justify-center items-center h-[50vh] relative">
        <div className="relative max-w-lg text-center header-content z-10 mt-48">
          <h1 className="text-4xl font-bold mb-4 overflow-hidden">
            Hi👋 I'm Yeabsra.
          </h1>
          <p className="text-lg mb-6">
            Passionate Full Stack Web Developer from Ethiopia📍
          </p>
          <Link to="/ContactMe">
            {/* <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
              Let's Build Your Product!
            </button> */}
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
