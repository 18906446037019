import React from "react";
// import { FaUpwork } from "react-icons/fa"; // Importing Upwork icon from react-icons

function Contactme() {
  return (
    <section
      id="contact"
      className="py-16 px-4 sm:px-8 lg:px-32 xl:px-40 bg-gray-900 text-white"
    >
      <h2 className="text-3xl font-bold mb-12 text-center overflow-hidden">
        Contact Me
      </h2>
      <div className="flex flex-col items-center">
        <p className="text-lg mb-6 text-center">
          If you have a project I can help you with, send me a message on
          Upwork.
        </p>
        <a
          href="https://www.upwork.com/freelancers/yeabsra" // Replace with your actual Upwork profile URL
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-2 bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {/* Upwork icon */}
          <span>My Upwork Profile</span>
        </a>
      </div>
    </section>
  );
}

export default Contactme;
