import React from "react";

const workExperience = [
  {
    title: "Front-end Developer",
    company: "YEABSRA.COM",
    duration: "Jan 2023 - Mar 2024",
    responsibilities: [
      "Designed and implemented a personal portfolio website using React, showcasing projects and skills.",
      "Engineered a user-friendly fitness tracker application to monitor workouts and progress.",
      "Developed a dynamic weather web app, providing real-time updates and forecasts.",
      "Demonstrated proficiency in React and front-end development through these projects.",
      "Custom Report builder to track, compare, and export key metrics.",
      "Support Management with ticket processing and communication center.",
    ],
  },
  {
    title: "Virtual Assistant",
    company: "Little Atlas",
    duration: "Apr 2023 - Feb 2024",
    responsibilities: [
      "Handled administrative tasks, scheduled appointments, and managed emails.",
      "Conducted research and data entry for various projects.",
      "Provided customer service support and managed social media accounts.",
    ],
  },
  {
    title: "Project Manager",
    company: "PXN Network",
    duration: "Feb 2022 - Apr 2022",
    responsibilities: [
      "Worked as an NFT manager for PXN Network. Handled Discord servers, and Twitter AMAs, and maintained project efficiency through well-proven work ethics.",
      "Managed Web3 inquiries and onboarding events.",
    ],
  },
];

const education = [
  {
    title: "Bachelor of Computer Science & Engineering",
    institution: "Unity University",
    duration: "2020 - 2024",
    details: "Graduated from Unity University. Addis Ababa Ethiopia",
  },

  {
    title: "Diploma on Natural Sciences",
    institution: "Safari High, Ethiopia",
    duration: "2015 - 2019",
    details: "Attended my High School School at Safari High, Ethiopia.",
  },
];

function Resume() {
  return (
    <section
      id="resume"
      className="py-16 px-4 sm:px-8 lg:px-32 xl:px-40 bg-gray-800 text-white"
    >
      <h2 className="text-3xl font-bold mb-12 text-center overflow-hidden">
        Resume
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        <div>
          <h3 className="text-2xl font-semibold mb-4 flex items-center">
            <svg
              className="w-6 h-6 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9.243 6.03a1.75 1.75 0 00-2.486 0l-5.032 5.031a1.75 1.75 0 002.475 2.475l5.032-5.031a1.75 1.75 0 000-2.475zM15.31 5.544a1.75 1.75 0 00-2.475 2.475l1.066 1.067L9.27 14.716a1.75 1.75 0 102.475 2.475l4.63-4.63 1.067 1.067a1.75 1.75 0 102.475-2.475L15.31 5.544z"
                clipRule="evenodd"
              />
            </svg>
            Work Experience
          </h3>
          {workExperience.map((job, index) => (
            <div key={index} className="mb-6">
              <div className="p-6 bg-gray-700 rounded-lg shadow-md">
                <h4 className="text-xl font-bold mb-2">{job.title}</h4>
                <p className="text-sm text-gray-300 mb-2">{job.company}</p>
                <p className="text-sm text-gray-400 mb-4">{job.duration}</p>
                <ul className="list-disc list-inside space-y-1 text-sm text-gray-200">
                  {job.responsibilities.map((resp, idx) => (
                    <li key={idx}>{resp}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div>
          <h3 className="text-2xl font-semibold mb-4 flex items-center">
            <svg
              className="w-6 h-6 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 9.293a1 1 0 011.414 0L10 13.586l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            Education
          </h3>
          {education.map((edu, index) => (
            <div key={index} className="mb-6">
              <div className="p-6 bg-gray-700 rounded-lg shadow-md">
                <h4 className="text-xl font-bold mb-2">{edu.title}</h4>
                <p className="text-sm text-gray-300 mb-2">{edu.institution}</p>
                <p className="text-sm text-gray-400 mb-4">{edu.duration}</p>
                {Array.isArray(edu.details) ? (
                  <ul className="list-disc list-inside space-y-1 text-sm text-gray-200">
                    {edu.details.map((detail, idx) => (
                      <li key={idx}>{detail}</li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-sm text-gray-200">{edu.details}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Resume;
