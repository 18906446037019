import React from "react";
import Header from "./components/Header";
import Projects from "./components/Projects";
import Technologies from "./components/Technologies";
import About from "./components/About";
// import Footer from "./components/Footer";
import Resume from "./components/Resume";
import Blog from "./components/Blog";
import Contactme from "./components/Contactme";
// import ThreeScene from "./components/ThreeScene";
import "./index.css";

function App() {
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Header />
      {/* <ThreeScene /> */}
      <main>
        <Projects />
        <Technologies />
        <About />
        <Resume />
      </main>
      {/* <Blog /> */}
      <Contactme />
    </div>
  );
}

export default App;
