import React from "react";
import { DiHtml5, DiCss3, DiJavascript1, DiReact } from "react-icons/di";
import { SiSass, SiBootstrap, SiFigma, SiAngular } from "react-icons/si";
import "./Technologies.css";

function Technologies() {
  const skills = [
    {
      name: "HTML",
      level: "Intermediate",
      icon: <DiHtml5 style={{ fontSize: "35px" }} />,
    },
    {
      name: "CSS, Sass & Bootstrap",
      level: "Intermediate",
      icon: <SiSass style={{ fontSize: "35px" }} />,
    },
    {
      name: "JavaScript, TypeScript, jQuery",
      level: "Intermediate",
      icon: <DiJavascript1 style={{ fontSize: "35px" }} />,
    },
    {
      name: "UI design in Figma",
      level: "Intermediate",
      icon: <SiFigma style={{ fontSize: "35px" }} />,
    },
    {
      name: "Angular",
      level: "Beginner",
      icon: <SiAngular style={{ fontSize: "35px" }} />,
    },
    {
      name: "React",
      level: "Intermediate",
      icon: <DiReact style={{ fontSize: "35px" }} />,
    },
    {
      name: "React Native",
      level: "Beginner",
      icon: <DiReact style={{ fontSize: "35px" }} />,
    },
  ];

  return (
    <section id="technologies" className="py-16 bg-gray-900 text-white">
      <h2 className="text-3xl font-bold mb-12 text-center overflow-hidden">
        Technologies I Am Fluent on 🖥️
      </h2>
      <div className="max-w-4xl mx-auto">
        {skills.map((skill, index) => (
          <div key={index} className="mb-6 flex items-center">
            <div className="mr-4">{skill.icon}</div>
            <div className="flex-1">
              <span className="block font-bold">{skill.name}</span>
              <div className="bg-gray-800 rounded-full h-4 mt-2">
                <div
                  className={`bg-purple-600 h-4 rounded-full ${
                    skill.level === "Beginner" ? "w-1/2" : "w-3/4"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Technologies;
