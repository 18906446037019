import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faTwitter,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

function About() {
  const milestones = [
    {
      year: 2018,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      year: 2019,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      year: 2020,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      year: 2021,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];

  return (
    <section id="about" className="py-16 px-4 sm:px-8 lg:px-32 xl:px-40">
      <h2 className="text-3xl font-bold mb-12 text-center text-white overflow-hidden">
        Brief Overview About Me 🔍
      </h2>
      <div className="max-w-4xl mx-auto px-4">
        <div className="bg-gray-800 bg-opacity-75 rounded-lg shadow-lg p-6">
          <p className="text-lg text-center mb-6 text-white">
            I'm a Computer Science grad with experience in React and Nodejs. I
            write clean, maintainable code and know how to use complex React
            patterns like context providers, consumer patterns, and higher-order
            components to keep my projects organized and reusable. I'm
            well-versed in managing state with Redux and handling data fetching
            with Redux Saga.
          </p>
          <p className="text-lg text-center mb-6 text-white">
            For making API calls, I'm comfortable with both Axios and the Fetch
            API. Lately, I've been diving into GraphQL with Apollo Client. I
            also use React hooks like useState, useCallback, and useEffect to
            manage state and fetch data in functional components.
          </p>
          <p className="text-lg text-center mb-6 text-white">
            To catch errors early, I use tools like Facebook's Flow plugin and
            TypeScript for type-checking. I also use Prettier and ESLint to keep
            my code clean and follow coding standards. For setting up projects,
            I can use frameworks like Create React App or React Boilerplate, or
            set up custom configurations with Babel and Webpack.
          </p>
          <p className="text-lg text-center mb-6 text-white">
            I mostly use Antd and tailwind for CSS frameworks, but I've tried
            React Material CSS too. I'm good at making responsive components
            using Antd's grid system or flexbox styling. On the backend, I can
            make strong APIs with Express, Mongoose, and MongoDB. I've started
            learning GraphQL and Apollo Server too. I'm good at communicating
            and working with others, whether it's following instructions
            precisely or breaking down big tasks into smaller ones."
          </p>
          <p className="text-lg text-center mb-6 text-white">
            🪓 I have also developed several APIs on the backend side using
            express alongside mongoose and mongodb, and recently with graphql
            and Apollo server.
            <p className="text-lg text-center mb-6 text-white">
              ✔️ I am an excellent communicator, and am good at following
              instructions. I enjoy making detailed plans, and love to
              modularize my work into maintainable pieces.{" "}
            </p>
            ⌚ I can work on the PT, EST, CEST, and AUST time zone and committed
            to 40 - 90 hours/week.
          </p>

          <p className="text-center">Let's connect on Socials!</p>
          <div className="max-w-4xl mx-auto px-4">
            <div>
              {/* Your content here */}
              <div className="flex justify-center space-x-4">
                <a
                  href="https://www.linkedin.com/in/yeabsra-henok/"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="text-white text-2xl hover:text-blue-400"
                  />
                </a>
                <a href="https://Twitter.com/yeabsra_eth" target="_blank">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="text-white text-2xl hover:text-blue-400"
                  />
                </a>
                <a href="https://github.com/yeabsra-henok" target="_blank">
                  <FontAwesomeIcon
                    icon={faGithub}
                    className="text-white text-2xl hover:text-blue-400"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
